<template>
  <div class="h-100 d-flex align-items-center justify-content-center" v-bind:class="{'flex-column' : mode === 'with_description'}">
    <div class="d-flex align-items-center points count" v-bind:class="{'single_number' : mode === 'single_number', 'with_description mb-2' : mode === 'with_description'}">      
        <img :src="apiBaseUrl + '/v1/common/cdn/file/image/base-dashboard/credits/' + environmentTag + '_credit.png/' + clientToken"/>
      <span>{{points | abbr}}</span>
    </div>
    <div v-if="pointsTitle" class="d-flex align-items-center justify-content-center">      
      <span>{{pointsTitle}}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PointsCount',
  props: ['mode', 'points', 'pointsTitle', 'environmentTag'],
  filters: {
    abbr: function(count) {
      let countString = String(count);
      let dotIndex = countString.indexOf(".");

      if(dotIndex > 0) countString = countString.substring(0, dotIndex);

      if(countString.length >= 7) {
        if(count % 1 != 0) {
          return (count/1000000).toFixed(1) + 'M';
        } else {
          return (count/1000000) + 'M';
        }
      } else if(countString.length >= 6) {
        if(count % 1 != 0) {
          return (count/1000).toFixed(1) + 'K';
        } else {
          return (count/1000) + 'K';
        }        
      } else {
        return count;
      }
    }
  },
  data() {
    return {
      apiBaseUrl: null,
      clientToken: null
    }
  },
  mounted: function() {
    this.apiBaseUrl = process.env.VUE_APP_API_URL;
    this.clientToken = localStorage.getItem('token');    
  }  
}
</script>
